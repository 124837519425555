<template>
	<form @submit.prevent="create()">
		<div class="form-image mb-5">
			<label>{{ title }}</label>
			<div v-show="imageURL != ''" class="form-group image-thumbnail">
				<img :src="imageURL" class="img-fluid">
			</div>

			<div class="form-group">
				<input v-show="false" type="file" ref="image_file_upload" class="form-control-file" @change="chooseFile">
			</div>
			<div class="mb-1">{{ filename }}</div>
			<button class="btn btn--sm" @click.prevent="triggerClick('image_file')">Upload File</button>

			<div class="form-group mt-5">
					<label for="image_playlist">{{ title }} Playlist</label>
					<v-select
						label="title"
						v-model="selectedPlaylist"
						:reduce="(option) => option.id"
						:options="playlistOptions"
						:loading="loading.playlists"
					>
						<template v-slot:option="option">{{ option.title }} - {{ option.url }}</template>
					</v-select>
			</div>
			<button
				type="submit"
				class="btn btn-primary"
				:disabled="loading.saving"
				v-text="saving ? 'Saving...' : 'Save'"
			/>
		</div>
	</form>
</template>

<script>
export default {
	name: 'SplashLinkForm',
	props: [ 'title', 'location', 'playlistId', 'image', 'loading', 'playlistOptions' ],
	data: () => ({
		imageURL: '',
		selectedPlaylist: null,
		filename: '',
		saving: false
	}),
	mounted () {
		this.imageURL = this.image
		this.selectedPlaylist = this.playlistId
	},
	methods: {
		triggerClick(e) {
			this.$refs.image_file_upload.click();
		},
		chooseFile() {
			if (this.$refs.image_file_upload.files.length) {
				this.filename = this.$refs.image_file_upload.files[0].name
			}
		},
		setLoading(loading) {
			this.saving = loading
			this.$emit('saving', loading)
		},
		create() {
			this.setLoading(true)
			const formData = new FormData()
			formData.append('playlist_id', this.selectedPlaylist)
			formData.append('location', this.location)
			if (this.$refs.image_file_upload.files.length) {
				formData.append('image', this.$refs.image_file_upload.files[0])
			}
			this.$http.post('/admin/splashlinks/update', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then(response => {
					this.setLoading(false)
					if (response.data.status == 'OK') {
						this.imageURL = response.data.splash_link.image
						this.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Splash page was updated'
            })
					}
				})
				.catch(err => {
					this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
					this.setLoading(false)
					console.log(err)
				})
		}
	}
}
</script>

<style scoped>
.form-image {
	padding: 22px;
	border-radius: 5px;
	background-color: #f5f5f5;
}

.form-image label {
	font-size: 19px;
}

.image-thumbnail {
	max-width: 100px;
}
</style>