<template>
	<div class="page-layout-wrapper">
		<div class="container">
			<div v-if="loading.splashLinks" class="text-center">
				Loading Splash Links...
			</div>
			<div v-else>
				<SplashLinkForm
					title="Main Image"
					location="main"
					:loading="loading"
					:playlistOptions="playlist_options"
					:playlistId="splash_links.main.playlist_id"
					:image="splash_links.main.image"
					@saving="(s) => loading.saving=s"
				/>

				<SplashLinkForm
					title="Top Right"
					location="top_right"
					:loading="loading"
					:playlistOptions="playlist_options"
					:playlistId="splash_links.top_right.playlist_id"
					:image="splash_links.top_right.image"
					@saving="(s) => loading.saving=s"
				/>

				<SplashLinkForm
					title="Middle Right"
					location="middle_right"
					:loading="loading"
					:playlistOptions="playlist_options"
					:playlistId="splash_links.middle_right.playlist_id"
					:image="splash_links.middle_right.image"
					@saving="(s) => loading.saving=s"
				/>

				<SplashLinkForm
					title="Bottom Right"
					location="bottom_right"
					:loading="loading"
					:playlistOptions="playlist_options"
					:playlistId="splash_links.bottom_right.playlist_id"
					:image="splash_links.bottom_right.image"
					@saving="(s) => loading.saving=s"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SplashLinkForm from '@/components/SplashLinkForm.vue'
import Vue from 'vue'

export default {
	name: 'Splash',
	components: {
		SplashLinkForm
	},
	data: () => ({
		splash_links: null,
		playlist_options: [],
		loading: {
			playlists: false,
			splashLinks: false,
			saving: false
		}
	}),
	methods: {
		fetchPlOptions() {
			this.loading.playlists = true
      const that = this
      this.$http.get('/public/playlists')
        .then(response => {
					console.log(response)
					this.loading.playlists = false
          if (response.data.msg == 'ok') {
            Vue.set(that, 'playlist_options', response.data.playlists)
          }
        })
        .catch(err => {
          that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
        })
    },
		fetchSplashLinks() {
			const that = this
			this.loading.splashLinks = true
			this.$http.get('/splashlinks')
				.then(response => {
					this.loading.splashLinks = false
					if (response.data.status == 'OK') {
						this.splash_links = response.data.splash_links;
					}
				})
				.catch(err => {
					that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
				})
		}
	},
	created () {
		this.fetchPlOptions()
		this.fetchSplashLinks()
	}
 }
</script>